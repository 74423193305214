import React, { PureComponent } from 'react';
import {
  SEO,
  LevelHero,
  Inspiration,
  LevelChallenge,
  LevelBenefits,
  LevelObjective
} from 'components';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import star from 'images/Levels/star.svg';
import level5Icon from 'images/Shared/LevelIcons/WhiteStroke/level_05_icon.svg';

class Level5 extends PureComponent {
  state = {
    componentHasMounted: false
  };

  render() {
    const { data, viewportWidth, user, game, selectedLevel } = this.props;
    const { levelData, sandFallingThroughHourglass } = data;
    const {
      benefits,
      challenge,
      duration,
      levelPageSummary,
      levelVideoCode,
      objective,
      scoring,
      title,
      subTitle
    } = levelData.childMarkdownRemark.frontmatter;
    let { tools } = levelData.childMarkdownRemark.frontmatter;
    if (game && game.uniqueDocs) {
      game.uniqueDocs.forEach((uniqueDocs) => {
        if(uniqueDocs.level === selectedLevel) {
          tools = {
            list: tools.list,
            link: uniqueDocs.link
          }
        }
      })
    }
    const { componentHasMounted } = this.state;
    return user ? (
      <Wrapper>
        <SEO pageSpecificTitle="Level 5" pageSpecificDescription={levelPageSummary} />
        <LevelHero
          level={5}
          title={title}
          subTitle={subTitle}
          levelPageSummary={levelPageSummary}
          levelIcon={level5Icon}
          levelVideoCode={levelVideoCode}
        />
        <LevelObjective objective={objective} />
        <LevelChallenge challenge={challenge} duration={duration} tools={tools} />
        <Overview>
          <div>
            <p>An allegorically insightful tale from a town near you:</p>
            <br />
            <p>
              Two loggers, one new to the game, the other with some experience, set out to see how
              many trees they could fell in a day to win the title of the town’s ‘logger of the
              year’. At the starting whistle, Mary the younger competitor and first time entrant,
              swung fast and strong all day. The only break she took was for water, some high energy
              bars and to flex her rippling biceps for the crowd. Billy, who had a bit of a thing
              for Mary but was too shy to say it, had competed 10 times in the competition, picking
              up a few lessons along the way.
            </p>
            <p>
              The crowd - remaining socially distanced - showed their appreciation all day and a
              huge roar went up at the final whistle. The judge, who also had a thing for Mary (it
              was that kind of town) entered the ring and to everyone’s surprise, raised Billy’s
              hand as the victor.
            </p>
            <br />
            <p>Mary had a conniption.</p>
            <br />
            <p>
              After she had calmed down a little, Mary said &quot;I've one question to ask you
              Billy. From the starting whistle to the very end, I summoned all my energy and lashed
              into the work like there was no tomorrow, whereas every so often, I could see you out
              of the corner of my eye, sitting back taking a breather, and still you ‘won’&quot;.
              (Mary could be very sarcastic when she wanted to be).
            </p>
            <br />
            <p>&quot;What’s going on there?&quot; she provocatively continued.</p>
            <br />
            <p>
              The crowd &quot;leaned in&quot;. Some of them had been to America on their holidays
              and had picked up the lingo.
            </p>
            <br />
            <p>
              Billy took a deep breath and blushed a little. &quot;I&apos;ve learned a few things
              over the years and breathing is one of them!&quot; The crowd liked that and egged
              Billy on. Billy continued.
            </p>
            <br />
            <p>
              &quot;Every so often I’d lift my head, take a good look at you and the way you were
              swinging&quot;.
            </p>
            <br />
            <p>A pause and a little more blushing.</p>
            <br />
            <p>
              <b>
                &quot;All the while I’d be sharpening my saw and prioritising the next line of trees
                to fell&quot;
              </b>
              .
            </p>
            <br />
            <p>
              Understanding that she had just encountered a profoundly amazing insight, Mary&apos;s
              tone softened. &quot;Where did you learn that?&quot; she asked admiringly.
            </p>
            <br />
            <p>
              &quot;In a book by Stephen Covey called &apos;The 7 Habits of Highly Effective
              People&apos;&quot; Billy responded bookishly, while putting on her bottle-end glasses.
            </p>
            <br />
            <p>And they all lived happily ever after, and with greater insight!</p>
            <br />
            <p>The End.</p>
          </div>
          <div>
            <LightGreenBox>
              <img src={star} alt="star" />
            </LightGreenBox>
            <p>
              <span>Insights</span> — Prioritise. Stay sharp. Complex tasks need complex solutions.
              Give yourself time to think. Don’t judge a book by its cover and remember that sarcasm
              isn&apos;t always a good thing!
            </p>
          </div>
        </Overview>
        <InnerWrapper>
          <LevelBenefits
            benefits={benefits}
            level={selectedLevel}
            objective={objective}
            viewportWidth={viewportWidth}
            scoring={scoring}
          />
        </InnerWrapper>
        <Inspiration
          img={sandFallingThroughHourglass}
          author="(Gandalf) JRR Tolkien"
          quote="All we have to do is decide what to do with the time we are given"
          quoteLargeFontSize="4rem"
          maxWidth="29.375em"
          textWrapperTop="6em"
          viewportWidth={viewportWidth}
        />
      </Wrapper>
    ) : (
      componentHasMounted && <Redirect to="/" noThrow />
    );
  }
}

const Wrapper = styled.section``;

const InnerWrapper = styled.div`
  max-width: 90em;
  margin: 0 9.1%;
`;

const Overview = styled.div`
  background-color: rgba(239, 237, 228, 0.3);
  padding: 0 9.027777777777777% 2.625rem;
  display: flex;
  flex-direction: column;

  div {
    max-width: 39.167em;
    margin: 0 auto 2.75em;
    width: 100%;
    font-size: 1.5rem;
  }

  div:last-of-type {
    align-items: flex-start;
    display: inline-flex;
    width: 100%;
    margin-bottom: 2.75em;

    > p {
      margin-left: 0.833em;
      position: relative;
      top: 0.325em;
      font-weight: 400;
      line-height: 1.333em;
      max-width: 874px;

      > span {
        font-weight: 700;
      }

      > span:nth-of-type(2) {
        margin-top: 1em;
      }

      > span:not(:first-of-type) {
        display: block;
        color: var(--cx-dark-green);
      }
    }
  }
`;

const LightGreenBox = styled.span`
  align-items: center;
  background-color: var(--cx-light-green);
  border-radius: 0.125em;
  bottom: 0.02em;
  color: #fff;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 600;
  height: 1.917em;
  justify-content: center;
  position: relative;
  width: 1.917em;
`;

export const Level5Query = graphql`
  {
    levelData: file(sourceInstanceName: { eq: "levelData" }, relativePath: { eq: "level_05.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          subTitle
          levelPageSummary
          levelVideoCode
          challenge {
            heading
            detail
          }
          objective
          benefits {
            forPlayers
            forTheCampaign
          }
          duration {
            time
            detail
          }
          scoring {
            challenge {
              points
              detail
            }
            teamGame {
              points
              detail
            }
          }
          tools {
            list
            link
          }
        }
      }
    }
    sandFallingThroughHourglass: file(
      relativePath: { eq: "Shared/Inspiration/sand_falling_through_hourglass.png" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 470, maxHeight: 620) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default Level5;
